
import { Options, Vue, Watch } from "vue-property-decorator";
import { parseTime } from "@/utils/index";
import * as api from "@/api/user";
@Options({
  name: "Myproject",
})
export default class extends Vue {
  refreshing = false;
  loading = false;
  finished = false;
  list: any = [];
  popupShow = false;
  currentDate = new Date();
  pickerValue = new Date();
  userInfo = {};
  checkinDays = 0;
  checkinMonth = 0;
  page = {
    pageSize: 10,
    pageNum: 1,
  };
  get showDate() {
    return parseTime(this.currentDate, "{y}年{m}月");
  }
  get monthDay() {
    let date = this.currentDate;
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let d = new Date(year, month, 0);
    return d.getDate();
  }
  mounted() {
    this.getUserInfo();
    this.getCheckCount();
    this.getCheckinMonthDays();
  }
  async getUserInfo() {
    const res: any = await api.userinfo({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.userInfo = res.data;
  }
  async getCheckCount() {
    const res: any = await api.checkinAllCount({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.checkinDays = res.data;
  }
  async getCheckinMonthDays() {
    const res: any = await api.checkinDays({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.checkinMonth = res.data;
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.list = [];
        this.page.pageNum = 1;
        this.refreshing = false;
      }
      api
        .checkinRecords({
          ...this.page,
          month: parseTime(this.currentDate, "{y}-{m}"),
          projectId: this.$store.state.project.projectinfo.projectId,
        })
        .then((res: any) => {
          if (res.code === 0) {
            this.list = [...this.list, ...res.data.records];
            this.loading = false;
            this.page.pageNum++;
            if (res.data.current >= res.data.pages) {
              this.finished = true;
            }
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    }, 1000);
  }
  fSelectTime(date: Date) {
    this.currentDate = date;
    this.popupShow = false;
    this.refreshing = true;
    this.onRefresh();
  }
  getDate(date: string) {
    return parseTime(new Date(date), "{y}-{m}-{d} (星期{a})");
  }
  getTime(date: string) {
    return parseTime(new Date(date), "{h}:{i}:{s}");
  }
  onRefresh() {
    // 清空列表数据
    this.finished = false;

    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.onLoad();
  }
}
